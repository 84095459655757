<template>
  <!-- 封装弹框 话题管理 敏感词 新增 删除 -->
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="20%"
      :before-close="handleClose"
    >
      <!-- 头像 -->
      <div v-if="topic" style="margin-bottom: 8px;">
        <div>头像</div>
        <div>
          <el-image :src="imgUrl"></el-image>
        </div>
        <div>
          <input type="file" @change="getFile($event)" />
        </div>
      </div>
      <!-- 简介 -->
      <div v-if="topic" style="margin-bottom: 8px;">
        <div style="margin-bottom: 8px;">简介</div>
        <el-input
          type="textarea"
          :rows="3"
          placeholder="请输入"
          v-model="introduction"
        >
        </el-input>
      </div>
      <div class="text" v-if="textShow">{{ deleteText }}</div>
      <div class="text_content">{{ textContent }}</div>
      <!-- 话题管理 -->
      <div v-if="delTopicShow">
        <el-input
          type="textarea"
          :rows="3"
          placeholder="请输入"
          disabled
          v-model="row.title"
        >
        </el-input>
      </div>

      <!-- 敏感词 -->
      <div v-if="delSensitiveShow">
        <el-input
          type="textarea"
          :rows="3"
          disabled
          v-model="row.sensitives"
        >
        </el-input>
      </div>
      <div v-if="!textShow">
        <el-input
          type="textarea"
          :rows="3"
          placeholder="请输入"
          v-model="textArea"
        >
        </el-input>
      </div>
      <div v-if="isShowRecordsnum" style="margin-bottom: 8px; margin-top: 8px;">
        <div style="margin-bottom: 8px;">话题人数</div>
        <el-input
          placeholder="请输入"
          v-model="ShowRecordsnum"
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="determine" type="danger">{{ buttonText }}</el-button>
        <el-button @click="cancel">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: [
    "isShowRecordsnum",
    "dialogVisible",
    "title",
    "textShow",
    "deleteText",
    "textContent",
    "buttonText",
    "row",
    "delTopicShow",
    "delSensitiveShow",
    "topic",
  ],
  data() {
    return {
      // 标题内容
      textArea: "",
      // 简介
      introduction: "",
      File: "",
      imgUrl: "",
      // 话题人数
      ShowRecordsnum: ''
    };
  },
  methods: {
    handleClose() {
      this.$parent.cancel();
    },
    // 弹框中取消按钮
    cancel() {
      this.$parent.cancel();
    },
    // 确定按钮
    determine() {
      if (this.buttonText == "新建") {
        this.$emit("addDialog", this.textArea, this.File, this.introduction, this.ShowRecordsnum);
      }
      if (this.buttonText == "删除") {
        this.$emit("delDialog", this.row);
      }
    },
    // 图片修改回调函数
    getFile(e) {
      this.File = e.target.files[0];
      this.imgUrl = this.getObjectURL(this.File);
    },
    getObjectURL(file) {
      let url = null;
      if (window.createObjectURL != undefined) {
        // basic
        url = window.createObjectURL(file);
      } else if (window.webkitURL != undefined) {
        // webkit or chrome
        url = window.webkitURL.createObjectURL(file);
      } else if (window.URL != undefined) {
        // mozilla(firefox)
        url = window.URL.createObjectURL(file);
      }
      return url;
    },
    // 清楚弹框中的数据
    clearData() {
      this.File = "";
      this.imgUrl = "";
      this.introduction = "";
      this.textArea = "";
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep .el-dialog__header {
  box-shadow: inset 0px -1px 0px #ebedf0;
}
.text {
  color: #df4545;
  font-size: 14px;
  font-weight: normal;
  line-height: 150%;
  margin-bottom: 24px;
}
.text_content {
  margin-bottom: 8px;
}
.dialog-footer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.el-button + .el-button {
  margin-left: 16px;
}
</style>